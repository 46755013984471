<template>
	<div class="description">
		<p
			class="description__text"
			:class="{ 'description__text--open': isFullText }"
			ref="description"
			v-html="content"
		/>
		<template v-if="isOverflowed">
			<transition name="fade">
				<span v-if="!isFullText" class="description__blur" />
			</transition>
			<button
				type="button"
				@click="isFullText = !isFullText"
				class="btn description__more"
				:class="{ active: isFullText }"
			>
				{{ isFullText ? $t('general.hide') : $t('general.learnMore') }}
				<or-icon type="arrow" :class="{ active: isFullText }" />
			</button>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		content: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			isFullText: false,
			descriptionInfo: undefined
		}
	},
	computed: {
		isOverflowed() {
			return (
				this.descriptionInfo?.scrollHeight > this.descriptionInfo?.offsetHeight
			)
		}
	},
	mounted() {
		this.descriptionInfo = this.$refs.description
	}
}
</script>

<style lang="scss">
.description {
	position: relative;

	&__text {
		display: flex;
		flex-direction: column;
		grid-gap: 10px;
		margin: 0;
		max-height: 300px;
		transition: max-height 0.5s cubic-bezier(0, 1.05, 0, 1);
		overflow-y: hidden;

		p {
			margin: 0;
		}

		&--open {
			display: block;
			margin: 0;
			padding: 0;
			max-height: 2000px;
			transition: max-height 0.3s ease-in-out;
			overflow: hidden;
		}
	}

	&__blur {
		position: absolute;
		width: 100%;
		height: 90%;
		bottom: 0;
		background: linear-gradient(
			0deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(255, 255, 255, 0) 100%
		);
	}

	&__more {
		position: relative;
		display: flex;
		gap: 11px;
		align-items: center;
		margin: 0 auto;
		margin-top: 10px;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #808080;
		background-color: #ffffff00;
		z-index: 11;

		&:focus {
			color: #000000;
		}

		&.active {
			color: #c99d56;
		}

		.icon {
			width: 24px;
			height: 24px;

			&:before {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 100%;
				height: 100%;
				font-size: 9px;
			}

			&.active:before {
				transform: rotate(180deg);
			}
		}
	}
}
</style>
