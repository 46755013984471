<template>
	<div class="image-block">
		<ul
			class="image-block__grid"
			:class="[`image-block__grid--${content?.length}`]"
		>
			<li
				v-for="(item, index) in content"
				class="image-block__item image-item"
				:key="index"
			>
				<iframe
					v-if="item.url"
					:src="correctVideoLink"
					:srcdoc="videoPreview"
					frameborder="0"
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowfullscreen
					style="width: 100%; height: 100%"
				/>
				<or-image
					v-else-if="item.files?.length"
					:src="item"
					:width="
						images?.length > 2 && index === 0
							? additionalImageSizes.width
							: imageSizes.width
					"
					:height="imageSizes.height"
					:alt="item.hash"
					@click="openImg(correctVideoLink ? index - 1 : index)"
					loading="auto"
				/>
			</li>
			<li
				v-if="images?.length - 5 > 0"
				class="image-block__item image-block__item--count image-count"
				@click="openImg(correctVideoLink ? 3 : 4)"
			>
				+{{ images?.length - 5 }}
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'ImageBlock',
	props: {
		images: {
			type: Array,
			default: () => []
		},
		video: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		imageSizes() {
			const isMobile = window.innerWidth <= 768
			// Суть в том, что когда у нас только одна катинка, то она растягивается на всю ширину и необходимо подгружать большее изображение
			// Тоже самое, когда у нас две картинки
			// Правила
			// 1) Одно фото, нет видео: для десктопа макс размер фото, для моб ширина экрана
			// 2) Одно фото и видео: Для десктопа 580 ширина, для мобилки ширина экранау

			// Первое условие
			if (this.images?.length === 1 && !this.video?.length) {
				if (isMobile) {
					return {
						width: window.innerWidth,
						height: 180
					}
				} else {
					return {
						width: 1180,
						height: 380
					}
				}
			}
			// Воторое условие
			else if (
				(this.images?.length === 1 && this.video?.length) ||
				(this.images?.length === 2 && !this.video?.length)
			) {
				if (isMobile) {
					return {
						width: window.innerWidth,
						height: 300
					}
				} else {
					return {
						width: 580,
						height: 380
					}
				}
			} else {
				return {
					width: 280
				}
			}
		},
		additionalImageSizes() {
			return {
				width: 580
			}
		},
		content() {
			return this.video?.length
				? Array(...this.video, ...this.images)?.slice(0, 5)
				: this.images?.slice(0, 5)
		},
		videoPreview() {
			if (this.correctVideoLink) {
				const previewImage = this.video[0].url
					.replace(/watch\?v=/i, 'vi/')
					.replace(/www/, 'img')
					.concat('/hqdefault.jpg')

				return `
        <style>
          * {
            padding: 0;
            margin: 0;
            overflow: hidden;
          }
          html,
          body {
            height: 100%;
          }
          a {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
          }
          span {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            background-color: #0000004D;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        </style>
        <a href=${this.correctVideoLink}?autoplay=1>
            <img src=${previewImage}>
          <span>
            <svg width="96" height="96" viewBox="0 0 96 96" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M84 48C84 67.8823 67.8823 84 48 84C28.1177 84 12 67.8823 12 48C12 28.1177 28.1177 12 48 12C67.8823 12 84 28.1177 84 48ZM88 48C88 70.0914 70.0914 88 48 88C25.9086 88 8 70.0914 8 48C8 25.9086 25.9086 8 48 8C70.0914 8 88 25.9086 88 48ZM41.1625 26.3725C40.5528 25.9371 39.751 25.8789 39.0848 26.2217C38.4187 26.5645 38 27.2508 38 28V68C38 68.7492 38.4187 69.4355 39.0848 69.7783C39.751 70.1211 40.5528 70.0629 41.1625 69.6275L69.1625 49.6275C69.6881 49.252 70 48.6459 70 48C70 47.3541 69.6881 46.748 69.1625 46.3725L41.1625 26.3725ZM64.5591 48L42 64.1136V31.8864L64.5591 48Z" fill="white"/>
            </svg>
          </span>
        </a>
        `
			} else {
				return ''
			}
		},
		correctVideoLink() {
			if (this.video && this.video[0]?.url) {
				return this.video[0].url.replace(/watch\?v=/i, 'embed/')
			} else {
				return ''
			}
		}
	},
	methods: {
		openImg(index) {
			this.$store.commit('showModal', {
				name: 'PlaceCarousel',
				props: {
					items: this.images,
					index
				}
			})
		}
	}
}
</script>

<style lang="scss">
.image-block {
	&__grid {
		position: relative;
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(4, 1fr);
		grid-template-rows: repeat(2, 180px);

		&--1 {
			justify-items: center;
			grid-template-columns: unset;
			grid-template-rows: unset;

			.image-item:first-child {
				width: 100%;
				height: 380px;

				img {
					width: 100%;
					object-fit: cover;
				}
			}
		}

		&--2 {
			.image-item {
				img {
					width: 580px;
					height: 380px;
				}
			}
		}
	}

	&__item {
		cursor: pointer;
		img {
			object-fit: cover;
			object-position: center;
			aspect-ratio: 16 / 10;
			height: 100%;
		}

		&:first-child {
			width: 580px;
			height: 380px;
			grid-column: 1/3;
			grid-row: 1/3;

			img {
				width: 580px;
				height: 380px;
			}
		}

		&--count {
			position: absolute;
			bottom: 0;
			right: 0;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 280px;
			height: 180px;
			color: white;
			font-weight: 700;
			font-size: 28px;
			background-color: #0000004d;
			border-radius: 4px;
		}

		.skeleton-basic {
			width: 100%;
			height: 100%;
		}
	}

	@media screen and (max-width: 1180px) {
		&__grid {
			&--2 {
				.image-item {
					width: 488px;

					img {
						width: 488px;
					}
				}
			}
		}

		&__item {
			width: 234px;

			img {
				width: 234px;
			}

			&:first-child {
				width: 488px;

				img {
					width: 488px;
				}
			}
		}
	}

	@media screen and (max-width: 1024px) {
		&__grid {
			&--2 {
				.image-item {
					width: 440px;

					img {
						width: 440px;
					}
				}
			}
		}

		&__item {
			width: 210px;

			img {
				width: 210px;
			}

			&:first-child {
				width: 440px;

				img {
					width: 440px;
				}
			}
		}
	}

	@media screen and (max-width: 912px) {
		&__grid {
			&--2 {
				.image-item {
					width: 374px;

					img {
						width: 374px;
					}
				}
			}
		}

		&__item {
			width: 177px;

			img {
				width: 177px;
			}

			&:first-child {
				width: 374px;

				img {
					width: 374px;
				}
			}
		}
	}

	@media screen and (max-width: 800px) {
		&__grid {
			&--2 {
				.image-item {
					width: 354px;

					img {
						width: 354px;
					}
				}
			}
		}

		&__item {
			width: 167px;

			img {
				width: 167px;
			}

			&:first-child {
				width: 354px;

				img {
					width: 354px;
				}
			}
		}
	}

	@media screen and (max-width: 768px) {
		&__grid {
			gap: 10px;
			justify-items: stretch;
			grid-template-columns: repeat(3, 1fr);
			grid-template-rows: 200px 70px;

			&--1 {
				grid-template-columns: 1fr;
				grid-template-rows: 380px;

				.image-item {
					height: 300px;

					&:first-child img {
						height: 300px;
					}
				}
			}

			&--2 {
				grid-template-columns: 1fr;
				grid-template-rows: 300px 300px;

				.image-item {
					width: 100%;
					height: 300px;

					img {
						width: 100%;
						height: 300px;
					}

					&:first-child img {
						height: 300px;
					}

					&:nth-last-child(2) {
						display: unset;
					}
				}

				.image-block__item {
					&:first-child {
						grid-column: 1/2;
					}
				}
			}

			&--3 {
				grid-template-columns: repeat(2, 1fr);

				.image-item {
					&:nth-last-child(2) {
						display: unset;
					}
				}
				.image-block__item {
					&:first-child {
						grid-column: 1/3;
					}
				}
			}

			&--4 {
				.image-item {
					&:nth-last-child(2) {
						display: unset;
					}
				}
			}
		}

		&__item {
			width: 100%;
			height: 70px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			&:first-child {
				width: 100%;
				height: auto;
				grid-column: 1/4;
				grid-row: 1/1;

				img {
					width: 100%;
					height: 200px;
				}
			}

			&:nth-last-child(2) {
				display: none;
			}

			&--count {
				width: 100%;
				grid-column: 3/4;
				grid-row: 2/2;
				font-size: 18px;
			}
		}
	}
}
</style>
